import { Box, Stack, SvgIcon } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { LinkButton } from 'components/Button/Button'
import {
  DownloadIocButton,
  DownloadToolButton,
  PurchaseToolButton,
} from 'components/Download/DownloadCta'
import { DownloadItem } from 'components/Download/DownloadItem'
import { ThreatHuntingTool } from 'components/Download/ThreatHuntingTool'
import { NoDataWithContactCta } from 'components/NoData/NoDataWithContactCta'
import { PageSection } from 'components/PageSection/PageSection'
import { DOWNLOAD_ROUTE } from 'constants/routeParams'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { toolTypes } from 'pages/DownloadPage/DownloadComponents/ToolsFilterBar'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchDownloadTools,
  fetchDownloadToolsCancelled,
  selectDownloadTools,
  selectDownloadToolsLoading,
} from 'store/slices/download'
import {
  fetchIocs,
  fetchIocsCancelled,
  selectIocs,
  selectIocsLoading,
} from 'store/slices/landing'

import { IocDownloadLoading } from './IocDownloadLoading'
import { ToolDownloadLoading } from './ToolDownloadLoading'

export const MAX_DOWNLOAD_ITEM_COUNT = 6

export const LandingDownload = () => {
  const dispatch = useAppDispatch()
  const iocsLoading = useAppSelector(selectIocsLoading)
  const iocs = useAppSelector(selectIocs)
  const toolsLoading = useAppSelector(selectDownloadToolsLoading)
  const tools = useAppSelector(selectDownloadTools)
  const { t } = useTranslation(['landingPage'])

  const ioCItems =
    iocs.length > 0 ? (
      iocs.slice(0, MAX_DOWNLOAD_ITEM_COUNT).map((ioc) => (
        <Box key={ioc.id}>
          <DownloadItem
            title={ioc.name}
            date={ioc.createdAt}
            downloadElement={<DownloadIocButton {...ioc} />}
          />
        </Box>
      ))
    ) : (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <NoDataWithContactCta />
      </Box>
    )

  const toolItems =
    tools.length > 0 ? (
      tools.slice(0, MAX_DOWNLOAD_ITEM_COUNT).map((tool) => {
        const downloadElement = tool.purchasedInformation ? (
          <PurchaseToolButton size="small" {...tool} />
        ) : (
          <DownloadToolButton size="small" {...tool} />
        )

        return (
          <Box key={tool.id}>
            <ThreatHuntingTool downloadElement={downloadElement} {...tool} />
          </Box>
        )
      })
    ) : (
      <Box
        sx={{
          width: '100%',
          py: '2.5rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <NoDataWithContactCta />
      </Box>
    )

  useEffect(() => {
    dispatch(fetchIocs())
    dispatch(fetchDownloadTools({ toolTypes }))

    return () => {
      dispatch(fetchIocsCancelled())
      dispatch(fetchDownloadToolsCancelled())
    }
  }, [])

  return (
    <Stack sx={{ gap: 1, height: '100%' }}>
      <Box sx={{ flexGrow: 1, flexBasis: 0, overflow: 'auto' }}>
        <PageSection
          title={t('ioc.title', { ns: 'landingPage' })}
          titleAction={
            <LinkButton
              to={`/${DOWNLOAD_ROUTE}`}
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
            >
              {t('ioc.moreCta', { ns: 'landingPage' })}
            </LinkButton>
          }
        >
          {iocsLoading ? (
            <IocDownloadLoading />
          ) : (
            <Stack sx={{ p: 1, height: '100%', gap: 1 }}>{ioCItems}</Stack>
          )}
        </PageSection>
      </Box>
      <Box sx={{ flexGrow: 1, flexBasis: 0, overflow: 'auto' }}>
        <PageSection
          title={t('threatHuntingTools.title', { ns: 'landingPage' })}
          titleAction={
            <LinkButton
              to={`/${DOWNLOAD_ROUTE}`}
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
            >
              {t('threatHuntingTools.moreCta', { ns: 'landingPage' })}
            </LinkButton>
          }
        >
          {toolsLoading ? (
            <ToolDownloadLoading />
          ) : (
            <Stack sx={{ p: 1, height: '100%', gap: 1 }}>{toolItems}</Stack>
          )}
        </PageSection>
      </Box>
    </Stack>
  )
}
