import { Box, Stack, Typography, useTheme } from '@mui/material'
import UnsubscribeMaskImage from 'assets/report/unsubscribe-mask.png'
import { DateLabel, ReportTypeLabel } from 'components/Label/Label'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { IReport } from 'store/types/entityTypes/report'

import { ReportPurchaseCta } from './ReportPurchaseCta'

interface IThreatLandscapeReportProps {
  report: IReport
  highlightText?: string
}

export const ThreatLandscapeReport = ({
  report,
  highlightText = '',
}: IThreatLandscapeReportProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  return (
    <Stack
      sx={{
        pt: 2,
        pb: 4,
        px: 4,
        overflowWrap: 'anywhere',
        cursor: report.permission ? 'pointer' : 'default',
        '&:hover': { bgcolor: theme.colors.BLACK_60 },
        '&:active': { bgcolor: theme.colors.BLACK_95 },
      }}
      to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${report.alias}`}
      component={report.permission ? Link : Stack}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 1 }}>
        <DateLabel text={getDateStringByTimestampsInSeconds(report.date)} />
        <ReportTypeLabel reportType={report.type} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {report.title}
        </Typography>
      </Box>
      <Box>
        {report.permission ? (
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            <Highlighter
              textToHighlight={
                report.digest.length > 600
                  ? `${report.digest.slice(0, 600)}...`
                  : report.digest
              }
              searchWords={[highlightText]}
              caseSensitive={false}
              highlightStyle={{
                color: theme.colors.HIGH_LIGHT,
                backgroundColor: 'inherit',
              }}
            />
          </Typography>
        ) : (
          <Box
            sx={{
              cursor: report.permission ? 'pointer' : 'default',
              position: 'relative',
            }}
          >
            <Image src={UnsubscribeMaskImage} alt="Unsubscribe" />
            <ReportPurchaseCta
              purchasedInformation={report.purchasedInformation}
              alias={report.alias}
            />
          </Box>
        )}
      </Box>
    </Stack>
  )
}
