import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_MALWARE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/malwares/search?query=Polaris" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "malwares":[
    {
      "name":"TVLoad",
      "aliases":[],
      "type":"Loader",
      "attribution":"Polaris",
      "overview":"TVLoad is a loader used by the Chinese APT group Polaris. The loader usually use the path \\"%Public%\\Libraries\\" for persistence and load the embedded AES-encrypted RAT. It was observed to load CobaltStrike Stager and NoFive. This name comes from that it usually create a directory with string \\"tv\\".",
      "last_updated_at": 1695916800
    },
    {
      "name":"CobaltStrike Beacon",
      "aliases":[],
      "type":"RAT",
      "attribution":"shared",
      "overview":"CobaltStrike Beacon is the payload of Cobalt Strike, a commercial penetration testing software used by various red teams, ethical hackers, and threat actors. It is highly customizable with features such as key logging, file transfer, SOCKS proxying, privilege escalation, and mimikatz. CobaltStrike is a legitimate tool used by ethical hackers as well as cyber arsenal used by threat actors to launch real attacks against companies and organizations.",
      "last_updated_at": 1695916800
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Search'

export const APIDocumentVersionTwoMalwareSearch = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_MALWARE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_MALWARE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Search for all related malware using keywords.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/malwares/search" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            query
            <UnorderedList>
              <ListItem>Keywords to filter malwares.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            malwares
            <UnorderedList>
              <ListItem>
                Array of related malwares, each object contains following keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>aliases: array of strings</ListItem>
                  <ListItem>type: string</ListItem>
                  <ListItem>attribution: strings</ListItem>
                  <ListItem>overview: text</ListItem>
                  <ListItem>last_updated_at: unix timestamp</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
