import { DOWNLOAD_DDW_REPORTS } from 'constants/api'
import { StatusCode } from 'constants/statusCode'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { logout } from 'store/slices/auth'
import {
  downloadDdwReports,
  downloadDdwReportsFulfilled,
  downloadDdwReportsRejected,
} from 'store/slices/ddw'
import { pushAlertSnackbar, pushSuccessSnackbar } from 'store/slices/snackbar'
import { downloadItem, downloadZipFile } from 'utils/download'
import { getArrayQueryString } from 'utils/queryString'

import { useAppDispatch } from './useReduxHooks'

export const useExportDDWReport = () => {
  const { t } = useTranslation(['snackbar'])
  const dispatch = useAppDispatch()
  const location = useLocation()

  const handleExportDDWReport = async (
    reportIds: string[],
    exportCallback?: () => void
  ) => {
    dispatch(downloadDdwReports())
    try {
      const data = await downloadItem({
        method: 'POST',
        itemUrl: `${DOWNLOAD_DDW_REPORTS}?${getArrayQueryString({
          report_ids: reportIds,
        })}`,
        format: 'zip',
      })

      if (data) {
        downloadZipFile({ value: data, filename: 'reports' })
        dispatch(downloadDdwReportsFulfilled())
      }

      if (exportCallback) {
        exportCallback()
      }

      dispatch(
        pushSuccessSnackbar({
          text: t('csv.exportSuccess', { ns: 'snackbar' }),
        })
      )
    } catch (error) {
      if ((error as Error).message === StatusCode.UNAUTHORIZED_TOKEN) {
        dispatch(
          logout({
            reason: 'expired',
            pathBeforeLogout: location.pathname + location.search,
          })
        )
      }

      dispatch(downloadDdwReportsRejected())
      dispatch(
        pushAlertSnackbar({
          text: t('csv.exportFail', { ns: 'snackbar' }),
        })
      )
    }
  }

  return { handleExportDDWReport }
}
