import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import { ICounts } from 'store/types/entityTypes/counts'
import { IIoc } from 'store/types/entityTypes/ioc'
import { IReport, TSearchReportType } from 'store/types/entityTypes/report'
import { ILandingSlice } from 'store/types/slicesTypes/landing'

export const initialLandingState: ILandingSlice = {
  latestReportsLoading: true,
  latestReports: [],
  iocsLoading: true,
  iocs: [],
  countsLoading: true,
  counts: {
    reportCount: {
      number: null,
      growth: false,
    },
    indicatorCount: {
      number: null,
      growth: false,
    },
    sampleCount: {
      number: null,
      growth: false,
    },
    malwareCount: {
      number: null,
      growth: false,
    },
    adversaryCount: {
      number: null,
      growth: false,
    },
  },
}

const landingSlice = createSlice({
  name: 'landing',
  initialState: initialLandingState,
  reducers: {
    fetchLatestReports(state, _action: PayloadAction<TSearchReportType[]>) {
      state.latestReportsLoading = true
    },
    fetchLatestReportsFulfilled(state, action: PayloadAction<IReport[]>) {
      state.latestReports = action.payload
      state.latestReportsLoading = false
    },
    fetchLatestReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.latestReports = initialLandingState.latestReports
      state.latestReportsLoading = false
    },
    fetchLatestReportsCancelled(state) {
      state.latestReportsLoading = false
    },
    fetchIocs(state) {
      state.iocsLoading = true
    },
    fetchIocsFulfilled(state, action: PayloadAction<IIoc[]>) {
      state.iocs = action.payload
      state.iocsLoading = false
    },
    fetchIocsRejected(state, _action: PayloadAction<AjaxError>) {
      state.iocs = initialLandingState.iocs
      state.iocsLoading = false
    },
    fetchIocsCancelled(state) {
      state.iocsLoading = false
    },
    fetchCounts(state) {
      state.countsLoading = true
    },
    fetchCountsFulfilled(state, action: PayloadAction<ICounts>) {
      state.counts = action.payload
      state.countsLoading = false
    },
    fetchCountsRejected(state, _action: PayloadAction<AjaxError>) {
      state.counts = initialLandingState.counts
      state.countsLoading = false
    },
    fetchCountsCancelled(state) {
      state.countsLoading = false
    },
  },
})

export const {
  fetchLatestReports,
  fetchLatestReportsFulfilled,
  fetchLatestReportsRejected,
  fetchLatestReportsCancelled,
  fetchIocs,
  fetchIocsFulfilled,
  fetchIocsRejected,
  fetchIocsCancelled,
  fetchCounts,
  fetchCountsFulfilled,
  fetchCountsRejected,
  fetchCountsCancelled,
} = landingSlice.actions

export const selectLatestReportsLoading = (state: TRootState) =>
  state.landing.latestReportsLoading
export const selectLatestReports = (state: TRootState) =>
  state.landing.latestReports
export const selectIocsLoading = (state: TRootState) =>
  state.landing.iocsLoading
export const selectIocs = (state: TRootState) => state.landing.iocs
export const selectCountsLoading = (state: TRootState) =>
  state.landing.countsLoading
export const selectCounts = (state: TRootState) => state.landing.counts

export default landingSlice.reducer
