import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { TRootState } from 'store'
import {
  IDdwCds,
  IDdwCl,
  IDdwContract,
  IDdwReport,
  IDdwReportMeta,
} from 'store/types/entityTypes/ddw'
import {
  IDdwSlice,
  IFetchDdwCdsPayload,
  IFetchDdwClPayload,
  IFetchDdwReportMetaPayload,
} from 'store/types/slicesTypes/ddw'

const initialState: IDdwSlice = {
  isFetchDdwContractsLoading: true,
  ddwContracts: [],
  isFetchDdwReportsLoading: true,
  ddwReports: [],
  isFetchDdwReportMetaLoading: false,
  ddwReportMeta: [],
  isFetchDdwClLoading: false,
  ddwCl: [],
  isFetchDdwCdsLoading: false,
  ddwCds: [],
  isDownloadDdwReportsLoading: false,
  showMosaicMask: false,
}

const ddwSlice = createSlice({
  name: 'ddw',
  initialState,
  reducers: {
    fetchDdwContracts(state) {
      state.isFetchDdwContractsLoading = true
    },
    fetchDdwContractsFulfilled(state, action: PayloadAction<IDdwContract[]>) {
      state.ddwContracts = action.payload
      state.isFetchDdwContractsLoading = false
    },
    fetchDdwContractsRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwContracts = initialState.ddwContracts
      state.isFetchDdwContractsLoading = false
    },
    fetchDdwContractsCancelled(state) {
      state.isFetchDdwContractsLoading = false
    },
    fetchDdwReports(state) {
      state.isFetchDdwReportsLoading = true
    },
    fetchDdwReportsFulfilled(state, action: PayloadAction<IDdwReport[]>) {
      state.ddwReports = action.payload
      state.isFetchDdwReportsLoading = false
    },
    fetchDdwReportsRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwReports = initialState.ddwReports
      state.isFetchDdwReportsLoading = false
    },
    fetchDdwReportsCancelled(state) {
      state.isFetchDdwReportsLoading = false
    },
    fetchDdwReportMeta(
      state,
      _action: PayloadAction<IFetchDdwReportMetaPayload>
    ) {
      state.isFetchDdwReportMetaLoading = true
    },
    fetchDdwReportMetaFulfilled(
      state,
      action: PayloadAction<IDdwReportMeta[]>
    ) {
      state.ddwReportMeta = action.payload
      state.isFetchDdwReportMetaLoading = false
    },
    fetchDdwReportMetaRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwReportMeta = initialState.ddwReportMeta
      state.isFetchDdwReportMetaLoading = false
    },
    fetchDdwReportMetaCancelled(state) {
      state.isFetchDdwReportMetaLoading = false
    },
    resetDdwReportMeta(state) {
      state.ddwReportMeta = initialState.ddwReportMeta
    },
    fetchDdwCl(state, _action: PayloadAction<IFetchDdwClPayload>) {
      state.isFetchDdwClLoading = true
    },
    fetchDdwClFulfilled(state, action: PayloadAction<IDdwCl[]>) {
      state.ddwCl = action.payload
      state.isFetchDdwClLoading = false
    },
    fetchDdwClRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwCl = initialState.ddwCl
      state.isFetchDdwClLoading = false
    },
    fetchDdwClCancelled(state) {
      state.isFetchDdwClLoading = false
    },
    fetchDdwCds(state, _action: PayloadAction<IFetchDdwCdsPayload>) {
      state.isFetchDdwCdsLoading = true
    },
    fetchDdwCdsFulfilled(state, action: PayloadAction<IDdwCds[]>) {
      state.ddwCds = action.payload
      state.isFetchDdwCdsLoading = false
    },
    fetchDdwCdsRejected(state, _action: PayloadAction<AjaxError>) {
      state.ddwCds = initialState.ddwCds
      state.isFetchDdwCdsLoading = false
    },
    fetchDdwCdsCancelled(state) {
      state.isFetchDdwCdsLoading = false
    },
    downloadDdwReports(state) {
      state.isDownloadDdwReportsLoading = true
    },
    downloadDdwReportsFulfilled(state) {
      state.isDownloadDdwReportsLoading = false
    },
    downloadDdwReportsRejected(state) {
      state.isDownloadDdwReportsLoading = false
    },
    downloadDdwReportsCancelled(state) {
      state.isDownloadDdwReportsLoading = false
    },
    setMosaicMask(state, action: PayloadAction<boolean>) {
      state.showMosaicMask = action.payload
    },
    resetMosaicMask(state) {
      state.showMosaicMask = initialState.showMosaicMask
    },
  },
})

export const {
  fetchDdwContracts,
  fetchDdwContractsFulfilled,
  fetchDdwContractsRejected,
  fetchDdwContractsCancelled,
  fetchDdwReports,
  fetchDdwReportsFulfilled,
  fetchDdwReportsRejected,
  fetchDdwReportsCancelled,
  fetchDdwReportMeta,
  fetchDdwReportMetaFulfilled,
  fetchDdwReportMetaRejected,
  fetchDdwReportMetaCancelled,
  resetDdwReportMeta,
  fetchDdwCl,
  fetchDdwClFulfilled,
  fetchDdwClRejected,
  fetchDdwClCancelled,
  fetchDdwCds,
  fetchDdwCdsFulfilled,
  fetchDdwCdsRejected,
  fetchDdwCdsCancelled,
  downloadDdwReports,
  downloadDdwReportsFulfilled,
  downloadDdwReportsRejected,
  downloadDdwReportsCancelled,
  setMosaicMask,
  resetMosaicMask,
} = ddwSlice.actions

export const selectFetchDdwContractsLoading = (state: TRootState) =>
  state.ddw.isFetchDdwContractsLoading
export const selectDdwContracts = (state: TRootState) => state.ddw.ddwContracts
export const selectFetchDdwReportsLoading = (state: TRootState) =>
  state.ddw.isFetchDdwReportsLoading
export const selectDdwReports = (state: TRootState) => state.ddw.ddwReports
export const selectLatestDdwReports = (state: TRootState) =>
  state.ddw.ddwReports[0]
export const selectDdwReportMeta = (state: TRootState) =>
  state.ddw.ddwReportMeta
export const selectFetchDdwReportMetaLoading = (state: TRootState) =>
  state.ddw.isFetchDdwReportMetaLoading
export const selectDdwCl = (state: TRootState) => state.ddw.ddwCl
export const selectFetchDdwClLoading = (state: TRootState) =>
  state.ddw.isFetchDdwClLoading
export const selectDdwCds = (state: TRootState) => state.ddw.ddwCds
export const selectFetchDdwCdsLoading = (state: TRootState) =>
  state.ddw.isFetchDdwCdsLoading
export const selectDownloadDdwReportsLoading = (state: TRootState) =>
  state.ddw.isDownloadDdwReportsLoading
export const selectShowMosaicMask = (state: TRootState) =>
  state.ddw.showMosaicMask

export default ddwSlice.reducer
