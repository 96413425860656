import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/Button/Button'
import { TextLink } from 'components/Link/Link'
import { PageSection } from 'components/PageSection/PageSection'
import { GroupingHeader, Select, SelectItem } from 'components/Select/Select'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { DDW_MONITOR_KEY } from 'constants/ddw'
import { PAGE_TITLE_DDW_ALERT } from 'constants/pageTitle'
import { NOT_FOUND_ROUTE } from 'constants/routeParams'
import { useDateTime } from 'hooks/useDatetime'
import { useExportDDWReport } from 'hooks/useExportDDWReport'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { ChangeEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectAccountSettings } from 'store/slices/account'
import {
  fetchDdwContracts,
  fetchDdwContractsCancelled,
  fetchDdwReportMeta,
  fetchDdwReportMetaCancelled,
  fetchDdwReports,
  fetchDdwReportsCancelled,
  resetDdwReportMeta,
  selectDdwContracts,
  selectDdwReportMeta,
  selectDdwReports,
  selectDownloadDdwReportsLoading,
  selectFetchDdwReportMetaLoading,
} from 'store/slices/ddw'
import { IDdwReportMeta } from 'store/types/entityTypes/ddw'
import { openMail } from 'utils/mail'

import { DDWAlertLoading } from './DDWAlertLoading'
import { ExportReportDialog } from './ExportReportDialog'
import { KeywordCardList } from './KeywordCardList'

const checkIsOneTimeContract = (startDate: number, endDate: number) =>
  startDate === endDate

export const DDWAlertPage = () => {
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { formatDuration, getDateStringByTimestampsInSeconds } = useDateTime()
  const contractList = useAppSelector(selectDdwContracts)
  const reportList = useAppSelector(selectDdwReports)
  const reportMetaList = useAppSelector(selectDdwReportMeta)
  const isFetchDdwReportMetaLoading = useAppSelector(
    selectFetchDdwReportMetaLoading
  )
  const accountSettings = useAppSelector(selectAccountSettings)
  const isDownloadDdwReportsLoading = useAppSelector(
    selectDownloadDdwReportsLoading
  )
  const { handleExportDDWReport } = useExportDDWReport()

  const subscribeDdwMonitor =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
    )
  const reportMetaEachRowList: IDdwReportMeta[][] = []

  for (let i = 0; i < reportMetaList.length; i += 5) {
    const chunk = reportMetaList.slice(i, i + 5)
    reportMetaEachRowList.push(chunk)
  }

  const [selectedContractId, setSelectedContractId] = useState<string>('')
  const [selectedReportId, setSelectedReportId] = useState<string>('')
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const [openExportDialog, setOpenExportDialog] = useState(false)
  const [showOneTimeReminder, setShowOneTimeReminder] = useState(true)

  const filterReportList = reportList.filter(
    ({ contractId }) => contractId === selectedContractId
  )

  const oneTimeContracts = contractList.filter((contract) =>
    checkIsOneTimeContract(contract.contractStartAt, contract.contractEndAt)
  )
  const periodContracts = contractList.filter(
    (contract) =>
      !checkIsOneTimeContract(contract.contractStartAt, contract.contractEndAt)
  )

  const selectedContract = contractList.find(
    (contract) => contract.contractId === selectedContractId
  )

  // 單次合約條件：合約起始時間等於合約結束時間
  const isSelectedContractOneTime = Boolean(
    selectedContract &&
      checkIsOneTimeContract(
        selectedContract?.contractStartAt,
        selectedContract?.contractEndAt
      )
  )

  const handleContractIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedContractId(event.target.value)
  }

  const handleReportIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReportId(event.target.value)
  }

  const handleKeywordChange = (keyword: string) => {
    setSelectedKeyword(keyword)
  }

  const handleExportDialogOpen = () => {
    setOpenExportDialog(true)
  }

  const handleExportDialogClose = () => {
    setOpenExportDialog(false)
  }

  const handleExportButtonClick = () => {
    if (isSelectedContractOneTime) {
      if (selectedReportId) {
        handleExportDDWReport([selectedReportId])
      }
    } else {
      handleExportDialogOpen()
    }
  }

  useEffect(() => {
    if (reportMetaEachRowList.length > 0) {
      const findSelectedKeywordRowIndex = reportMetaEachRowList
        .map((list) => list.find(({ keyword }) => keyword === selectedKeyword))
        .findIndex((row) => row)

      const scrollableContainer = document.getElementById(
        String(findSelectedKeywordRowIndex)
      )

      if (scrollableContainer) {
        scrollableContainer.scrollIntoView()
      }
    }
  }, [selectedKeyword])

  useEffect(() => {
    dispatch(fetchDdwReports())
    dispatch(fetchDdwContracts())
    return () => {
      dispatch(fetchDdwReportsCancelled())
      dispatch(fetchDdwContractsCancelled())
    }
  }, [])

  useEffect(() => {
    if (filterReportList.length > 0) {
      setSelectedReportId(filterReportList[0].reportId)
    } else {
      setSelectedReportId('')
    }
  }, [filterReportList.length, selectedContractId])

  useEffect(() => {
    if (contractList.length > 0) {
      setSelectedContractId(contractList[0].contractId)
    }
  }, [contractList.length])

  useEffect(() => {
    // 切換 report 時不選 keyword
    setSelectedKeyword('')

    if (selectedReportId) {
      dispatch(fetchDdwReportMeta({ id: selectedReportId }))
    } else {
      dispatch(resetDdwReportMeta())
    }

    return () => {
      dispatch(fetchDdwReportMetaCancelled())
      dispatch(resetDdwReportMeta())
    }
  }, [selectedReportId])

  useEffect(() => {
    if (
      accountSettings.otherServicesSubscriberGroups !== null &&
      !subscribeDdwMonitor
    )
      navigate(`/${NOT_FOUND_ROUTE}`)
  }, [accountSettings])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DDW_ALERT}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Stack sx={{ p: 1, gap: 1, height: '100%' }}>
          <PageSection
            title={t('ddwAlert.title', { ns: 'ddw' })}
            titleAction={
              contractList.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    color: theme.colors.WHITE,
                  }}
                >
                  <Typography
                    variant="textSmallImportant"
                    sx={{ lineHeight: '0.75rem', flexShrink: 0 }}
                  >
                    {t('ddwAlert.contractPeriod', { ns: 'ddw' })}
                  </Typography>
                  {contractList.length > 1 ? (
                    <Select
                      value={selectedContractId}
                      onChange={handleContractIdChange}
                      sx={{ width: '15rem' }}
                    >
                      {oneTimeContracts.length > 0 && (
                        <GroupingHeader
                          text={t('ddwAlert.contractSelector.oneTime', {
                            ns: 'ddw',
                          })}
                        />
                      )}
                      {oneTimeContracts.map((contract) => (
                        <SelectItem
                          key={contract.contractId}
                          value={contract.contractId}
                        >
                          {getDateStringByTimestampsInSeconds(
                            contract.contractStartAt / 1000
                          )}
                          {contract.trial && ' PoC'}
                        </SelectItem>
                      ))}
                      {periodContracts.length > 0 && (
                        <GroupingHeader
                          text={t('ddwAlert.contractSelector.weekly', {
                            ns: 'ddw',
                          })}
                        />
                      )}
                      {periodContracts.map((contract) => (
                        <SelectItem
                          key={contract.contractId}
                          value={contract.contractId}
                        >
                          {formatDuration(
                            contract.contractStartAt / 1000,
                            contract.contractEndAt / 1000
                          )}
                          {contract.trial && ' PoC'}
                        </SelectItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography variant="body">
                      {formatDuration(
                        contractList[0].contractStartAt / 1000,
                        contractList[0].contractEndAt / 1000
                      )}
                    </Typography>
                  )}
                  <Button
                    endIcon={
                      <SvgIcon component={DownloadIcon} inheritViewBox />
                    }
                    onClick={handleExportButtonClick}
                    loading={isDownloadDdwReportsLoading}
                    disabled={
                      filterReportList.length === 0 ||
                      isDownloadDdwReportsLoading
                    }
                  >
                    {t('ddwAlert.exportCsv', { ns: 'ddw' })}
                  </Button>
                </Box>
              ) : (
                <Skeleton type="body" width="8.5rem" />
              )
            }
            childrenOverflowStyle="hidden"
          >
            <Stack sx={{ height: '100%' }}>
              {isSelectedContractOneTime ? (
                showOneTimeReminder &&
                selectedContract?.trial && (
                  <Box sx={{ p: 2 }}>
                    <Alert
                      content={
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="body" color={theme.colors.WHITE}>
                            <Trans t={t} i18nKey="ddwAlert.oneTimePoCReminder">
                              You are currently on a trial plan. The total
                              number of leaked data is shown below. If you have
                              any questions please
                              <TextLink size="medium" onClick={openMail} to="">
                                contact us
                              </TextLink>
                              .
                            </Trans>
                          </Typography>
                        </Box>
                      }
                      onClose={() => setShowOneTimeReminder(false)}
                    />
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    bgcolor: theme.colors.BLACK_95,
                    p: 2,
                  }}
                >
                  <Typography
                    variant="textSmallImportant"
                    sx={{ color: theme.colors.WHITE, flexShrink: 0 }}
                  >
                    {t('ddwAlert.monitoringInterval', { ns: 'ddw' })}
                  </Typography>
                  <Select
                    disabled={filterReportList.length === 0}
                    value={selectedReportId}
                    onChange={handleReportIdChange}
                    sx={{ width: '15rem' }}
                  >
                    {filterReportList.map((reportDurationCount) => (
                      <SelectItem
                        key={reportDurationCount.reportId}
                        value={reportDurationCount.reportId}
                      >
                        {formatDuration(
                          reportDurationCount.reportStartAt / 1000,
                          reportDurationCount.reportEndAt / 1000
                        )}
                        ({reportDurationCount.reportCount})
                      </SelectItem>
                    ))}
                  </Select>
                </Box>
              )}

              <Stack sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                {isFetchDdwReportMetaLoading ? (
                  <DDWAlertLoading />
                ) : reportMetaEachRowList.length > 0 ? (
                  reportMetaEachRowList.map((metaList, index) => (
                    <KeywordCardList
                      id={String(index)}
                      key={metaList[0]?.keyword}
                      metaList={metaList}
                      selectedReportId={selectedReportId}
                      selectedKeyword={selectedKeyword}
                      handleKeywordChange={handleKeywordChange}
                      isSelectedContractOneTimePoC={
                        isSelectedContractOneTime &&
                        Boolean(selectedContract?.trial)
                      }
                    />
                  ))
                ) : (
                  <Stack sx={{ py: 12, gap: 2, alignItems: 'center' }}>
                    <Typography
                      variant="titleBig"
                      sx={{
                        color: theme.colors.WHITE_60,
                        textAlign: 'center',
                      }}
                    >
                      <Trans t={t} i18nKey="ddwAlert.emptyState.noResult">
                        You have successfully subscribed to Deep and Dark Web
                        Alert !<br /> The monitoring results will soon be
                        delivered.
                      </Trans>
                    </Typography>
                    <Image
                      src={SmileTVImage}
                      alt="You have successfully subscribed to Deep and Dark Web Alert ! The monitoring results will soon be delivered."
                      width={328}
                      height={130}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </PageSection>
        </Stack>
        <ExportReportDialog
          open={openExportDialog}
          handleClose={handleExportDialogClose}
          reportList={filterReportList}
        />
      </Box>
    </>
  )
}
