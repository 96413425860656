import { Box, Stack } from '@mui/material'
import { Skeleton } from 'components/Skeleton/Skeleton'
import { generateSkeletons } from 'utils/generateSkeletons'

export const COUNTS_BOARD_LOADING_TEST_ID = 'counts-board-loading'

const LoadingBlock = () => (
  <Stack
    sx={{
      height: '100%',
      flexGrow: 1,
      gap: 2,
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: (theme) => theme.colors.BLACK_90,
    }}
  >
    <Skeleton type="body" width="6rem" />
    <Skeleton type="body" width="6rem" />
  </Stack>
)

export const CountsBoardLoading = () => (
  <Box
    data-testid={COUNTS_BOARD_LOADING_TEST_ID}
    sx={{
      width: '100%',
      height: '3.25rem',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      gap: 1,
    }}
  >
    {generateSkeletons(5, <LoadingBlock />)}
  </Box>
)
