import { Box, Stack, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { PAGE_TITLE_LANDING } from 'constants/pageTitle'
import { useAppSelector } from 'hooks/useReduxHooks'
import { Helmet } from 'react-helmet'
import { selectAccountSettings } from 'store/slices/account'

import { CountsBoard } from './CountsBoard/CountsBoard'
import { LandingDownload } from './LandingDownload/LandingDownload'
import { LatestDDWReportCount } from './LatestDDWReportCount/LatestDDWReportCount'
import { LatestReports } from './LatestReports/LatestReports'

export const LANDING_PAGE_CONTAINER_TESTID = 'landing-page-container-testid'

const DDW_MONITOR_KEY = 'deep_dark_web_monitor'

export const LandingPage = () => {
  const theme = useTheme()
  const accountSettings = useAppSelector(selectAccountSettings)
  const isSubscribeDdwMonitor =
    accountSettings.otherServicesSubscriberGroups?.find(
      (otherServicesSubscriberGroup) =>
        otherServicesSubscriberGroup.key === DDW_MONITOR_KEY
    )

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_LANDING}</title>
      </Helmet>
      <Stack
        data-testid={LANDING_PAGE_CONTAINER_TESTID}
        sx={{
          p: 1,
          gap: 1,
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            height: '3.25rem',
          }}
        >
          <CountsBoard />
        </Box>
        <Grid
          container
          sx={{
            flexBasis: 1,
            flexGrow: 1,
            overflow: 'hidden',
            flexWrap: 'nowrap',
          }}
          columnSpacing={1}
        >
          <Grid md={6} sx={{ height: '100%' }}>
            <LatestReports />
          </Grid>
          <Grid md={6}>
            <Stack sx={{ height: '100%', gap: 1 }}>
              {isSubscribeDdwMonitor && (
                <Box sx={{ flexShrink: 0 }}>
                  <LatestDDWReportCount />
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <LandingDownload />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}
