export interface IDdwContractAPIData {
  contract_id: string
  contract_start_at: number
  contract_end_at: number
  trial: boolean
}

export interface IDdwContract {
  contractId: string
  contractStartAt: number
  contractEndAt: number
  trial: boolean
}

export const mapAPIDdwContractsToState = (
  fromAPIDdwContracts: IDdwContractAPIData[]
): IDdwContract[] =>
  fromAPIDdwContracts.map((fromAPIDdwContract) => ({
    contractId: fromAPIDdwContract.contract_id,
    contractStartAt: fromAPIDdwContract.contract_start_at,
    contractEndAt: fromAPIDdwContract.contract_end_at,
    trial: fromAPIDdwContract.trial,
  }))

export interface IDdwReportAPIData {
  report_id: string
  contract_id: string
  report_start_at: number
  report_end_at: number
  report_count: number
}

export interface IDdwReport {
  reportId: string
  contractId: string
  reportStartAt: number
  reportEndAt: number
  reportCount: number
}

export const mapAPIDdwReportsToState = (
  fromAPIDdwReports: IDdwReportAPIData[]
): IDdwReport[] =>
  fromAPIDdwReports.map((fromAPIDdwReport) => ({
    reportId: fromAPIDdwReport.report_id,
    contractId: fromAPIDdwReport.contract_id,
    reportStartAt: fromAPIDdwReport.report_start_at,
    reportEndAt: fromAPIDdwReport.report_end_at,
    reportCount: fromAPIDdwReport.report_count,
  }))

export interface IDdwReportMetaAPIData {
  keyword: string
  cl_count: number
  cds_count: number
}

export interface IDdwReportMeta {
  keyword: string
  clCount: number
  cdsCount: number
}

export const mapAPIDdwReportsMetaToState = (
  fromAPIDdwReportsMeta: IDdwReportMetaAPIData[]
): IDdwReportMeta[] =>
  fromAPIDdwReportsMeta.map((fromAPIDdwReportMeta) => ({
    keyword: fromAPIDdwReportMeta.keyword,
    clCount: fromAPIDdwReportMeta.cl_count,
    cdsCount: fromAPIDdwReportMeta.cds_count,
  }))

export interface IDdwClAPIData {
  id: string | null
  leaked_from: string | null
  leaked_at: number | null
  domain: string | null
  email: string | null
  password: string
}

export interface IDdwCl {
  id: string | null
  leakedFrom: string | null
  leakedAt: number | null
  domain: string | null
  email: string | null
  password: string
}

export const mapAPIDdwClToState = (fromAPICls: IDdwClAPIData[]): IDdwCl[] =>
  fromAPICls.map((fromAPICl) => ({
    id: fromAPICl.id,
    leakedFrom: fromAPICl.leaked_from,
    leakedAt: fromAPICl.leaked_at,
    domain: fromAPICl.domain,
    email: fromAPICl.email,
    password: fromAPICl.password,
  }))

export interface IDdwCdsAPIData {
  id: string
  host: string | null
  user: string | null
  password: string
  leaked_at: number | null
  regdate: number | null
  ip: string | null
  username: string | null
  computername: string | null
}

export interface IDdwCds {
  id: string
  host: string | null
  user: string | null
  password: string
  leakedAt: number | null
  regdate: number | null
  ip: string | null
  username: string | null
  computername: string | null
}

export const mapAPIDdwCdsToState = (fromAPICdss: IDdwCdsAPIData[]): IDdwCds[] =>
  fromAPICdss.map((fromAPICds) => ({
    id: fromAPICds.id,
    host: fromAPICds.host,
    user: fromAPICds.user,
    password: fromAPICds.password,
    leakedAt: fromAPICds.leaked_at,
    regdate: fromAPICds.regdate,
    ip: fromAPICds.ip,
    username: fromAPICds.username,
    computername: fromAPICds.computername,
  }))
