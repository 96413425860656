import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/3dcb76d6eb3c544c66cf7568b2522e0eea329f93c607e4b8c109a1e4baa42cbb/adversaries" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"3dcb76d6eb3c544c66cf7568b2522e0eea329f93c607e4b8c109a1e4baa42cbb",
  "adversaries":[
    {
      "name":"EtherBei",
      "aliases":["Flax Typhoon", "Etheral Panda"],
      "origin_countries":["China"],
      "targeted_countries":[
        "South Korea",
        "Malaysia",
        "Nepal",
        "Turkey",
        "Kenya",
        "Hong Kong",
        "Thailand",
        "Taiwan",
        "Vietnam",
        "India",
        "Japan"
      ],
      "targeted_industries":[
        "Media",
        "Education",
        "Energy",
        "Government",
        "Critical Infrastructure",
        "Telecommunication",
        "Semiconductor",
        "Healthcare",
        "Think tank",
        "Information Technology",
        "Transportation"
      ],
      "overview":"First seen in 2017, EtherBei (aka Flax Typhoon, Ethereal Panda) is a China-nexus Advanced Persistent Threat (APT) group that relies on Living-off-the-Land (LotL) techniques to deliver malware and lurk inside the victims' systems. EtherBei's distinctive TTPs include leveraging SoftEther VPN and vulnerability exploitation. EtherBei is the merger of SLIME13, SLIME46, and SLIME_TT. EtherBei targets government agencies, information technology companies, education entities, and critical infrastructure in Taiwan. Besides Taiwan, EtherBei also targets entities in Japan, South Korea, as well as countries in Southeast Asia and even Africa.",
      "last_updated_at": 1695916800
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Adversaries'

export const APIDocumentVersionTwoSampleAdversaries = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Lists all attack groups that may use the sample.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/:sha256/adversaries" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The sha256 for sample</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            adversaries
            <UnorderedList>
              <ListItem>
                Array of related adversaries, each object contains following
                keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>aliases: array of strings</ListItem>
                  <ListItem>origin_countries: array of strings</ListItem>
                  <ListItem>target_countries: array of strings</ListItem>
                  <ListItem>target_industries: array of strings</ListItem>
                  <ListItem>overview: text</ListItem>
                  <ListItem>last_updated_at: unix timestamp</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
