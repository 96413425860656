import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ReactComponent as ArrowUpIcon } from 'assets/basicIcons/arrowUp.svg'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchCounts,
  fetchCountsCancelled,
  selectCounts,
  selectCountsLoading,
} from 'store/slices/landing'
import { ICounts } from 'store/types/entityTypes/counts'

import { CountsBoardLoading } from './CountsBoardLoading'

export const COUNTS_BOARD_CONTAINER_TEST_ID = 'counts-board-container'

export const CountsBoard = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const countsLoading = useAppSelector(selectCountsLoading)
  const counts = useAppSelector(selectCounts)

  const countsNotReady = Object.values(counts).some((count) => count === null)

  const { t } = useTranslation(['landingPage'])

  const countsDisplayNameMap: Record<keyof ICounts, string> = {
    reportCount: t('countsBoard.reports', { ns: 'landingPage' }),
    indicatorCount: t('countsBoard.indicators', { ns: 'landingPage' }),
    sampleCount: t('countsBoard.samples', { ns: 'landingPage' }),
    malwareCount: t('countsBoard.malware', { ns: 'landingPage' }),
    adversaryCount: t('countsBoard.adversaries', { ns: 'landingPage' }),
  }

  useEffect(() => {
    dispatch(fetchCounts())
    return () => {
      dispatch(fetchCountsCancelled())
    }
  }, [])

  const countList = Object.keys(counts).map((key: keyof ICounts) => ({
    name: key,
    count: counts[key],
  }))

  return (
    <Grid
      container
      sx={{ height: '100%', gap: 1 }}
      data-testid={COUNTS_BOARD_CONTAINER_TEST_ID}
    >
      {countsLoading || countsNotReady ? (
        <CountsBoardLoading />
      ) : (
        countList.map((countItem) => (
          <Grid
            key={countItem.name}
            sx={{
              bgcolor: theme.colors.BLACK_90,
              textAlign: 'center',
              flexGrow: 1,
              borderRadius: 0.5,
              py: 1,
            }}
          >
            <Stack sx={{ gap: 0.5 }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 6,
                    borderBottom: '1px solid',
                    borderImage: `linear-gradient(to right, ${theme.colors.BLACK_90} 25%, ${theme.colors.PRIMARY} 50%, ${theme.colors.BLACK_90} 75%) 1`,
                  }}
                >
                  <Typography
                    variant="title"
                    sx={{
                      color: theme.colors.PRIMARY,
                    }}
                  >
                    {countItem.count?.number?.toLocaleString()}
                  </Typography>
                  {countItem.count?.growth && (
                    <SvgIcon
                      sx={{
                        width: '1rem',
                        height: '1rem',
                        pl: 0.5,
                        path: {
                          fill: theme.colors.TLPGreen,
                          fillOpacity: 1,
                        },
                      }}
                      component={ArrowUpIcon}
                      inheritViewBox
                    />
                  )}
                </Box>
              </Box>
              <Typography
                variant="textSmallImportant"
                sx={{ color: theme.colors.WHITE_60 }}
              >
                {countsDisplayNameMap[countItem.name]}
              </Typography>
            </Stack>
          </Grid>
        ))
      )}
    </Grid>
  )
}
