import { Box, Divider, Typography, useTheme } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { ThreatLandscapeReport } from 'components/Report/ThreatLandscapeReport'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { TTargetType } from 'pages/ThreatLandscapePage/TargetBlock'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchCountryRelatedReports,
  fetchIndustryRelatedReports,
  fetchRegionRelatedReports,
  fetchRelatedReportsCancelled,
  selectRelatedReports,
  selectRelatedReportsLoading,
} from 'store/slices/threatLandscape'

import { Loading } from './Loading'

interface IRelatedReports {
  displayName: string
  name: string
  targetType: TTargetType
}

export const RELATED_REPORT_CONTAINER_TEST_ID = 'related-report-container'

const RelatedReports = ({ displayName, name, targetType }: IRelatedReports) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation(['threatLandscapes', 'component'])
  const relatedReportsLoading = useAppSelector(selectRelatedReportsLoading)
  const relatedReports = useAppSelector(selectRelatedReports)

  useEffect(() => {
    if (targetType === 'country') {
      dispatch(fetchCountryRelatedReports(name))
    } else if (targetType === 'industry') {
      dispatch(fetchIndustryRelatedReports(name))
    } else if (targetType === 'region') {
      dispatch(fetchRegionRelatedReports(name))
    }
    return () => {
      dispatch(fetchRelatedReportsCancelled())
    }
  }, [targetType, name])

  return (
    <PageSection title={t('relatedReports', { ns: 'threatLandscapes' })}>
      <Box data-testid={RELATED_REPORT_CONTAINER_TEST_ID} />
      {relatedReportsLoading ? (
        <Loading />
      ) : relatedReports.length > 0 ? (
        <Box sx={{ p: 2 }}>
          {relatedReports.map((report, index) => (
            <Box key={report.id}>
              {index !== 0 && (
                <Divider sx={{ borderColor: theme.colors.WHITE_20, mb: 2 }} />
              )}
              <ThreatLandscapeReport
                report={report}
                highlightText={displayName}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography
          variant="titleSmall"
          sx={{ color: theme.colors.WHITE_60, p: 2 }}
        >
          {t('emptyState.noRelatedResult', { ns: 'component' })}
        </Typography>
      )}
    </PageSection>
  )
}

export default RelatedReports
