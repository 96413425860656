export interface ICounts {
  reportCount: {
    number: number | null
    growth: boolean
  }
  indicatorCount: {
    number: number | null
    growth: boolean
  }
  sampleCount: {
    number: number | null
    growth: boolean
  }
  malwareCount: {
    number: number | null
    growth: boolean
  }
  adversaryCount: {
    number: number | null
    growth: boolean
  }
}

interface ICountsAPIData {
  report_count: number
  report_count_growth: boolean
  indicator_count: number
  indicator_count_growth: boolean
  sample_count: number
  sample_count_growth: boolean
  malware_count: number
  malware_count_growth: boolean
  adversary_count: number
  adversary_count_growth: boolean
}

export interface IFetchCountsResponse {
  success: boolean
  data: ICountsAPIData
}

export const mapAPICountsToState = (
  fromAPICounts: ICountsAPIData
): ICounts => ({
  reportCount: {
    number: fromAPICounts.report_count,
    growth: fromAPICounts.report_count_growth,
  },
  indicatorCount: {
    number: fromAPICounts.indicator_count,
    growth: fromAPICounts.indicator_count_growth,
  },
  sampleCount: {
    number: fromAPICounts.sample_count,
    growth: fromAPICounts.sample_count_growth,
  },
  malwareCount: {
    number: fromAPICounts.malware_count,
    growth: fromAPICounts.malware_count_growth,
  },
  adversaryCount: {
    number: fromAPICounts.adversary_count,
    growth: fromAPICounts.adversary_count_growth,
  },
})
