import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_MALWARE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/malwares/TVLoad/adversaries" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"TVLoad",
  "adversaries":[
    {
      "name":"Polaris",
      "aliases":[
        "Mustang Panda",
        "HoneyMyte",
        "Earth Preta"
      ],
      "origin_countries":["China"],
      "targeted_countries":[
        "South Korea",
        "Japan",
        "Vietnam",
        "Thailand",
        "Myanmar",
        "Philippines",
        "Mongolia",
        "Taiwan",
        "Australia",null,
        "Singapore",
        "Hong Kong",
        "India",
        "Malaysia",
        "Indonesia"
      ],
      "targeted_industries":[
        "Media",
        "Education",
        "Energy",
        "Government",
        "Dissidents",
        "Telecommunication",
        "Research Institute"
      ],
      "overview":"The Polaris group has been active since at least 2011. It has been a major threats to almost all Asian countries, especially neighboring countries of China. Since Ukraine-Russia conflict started in 2022, it also expanded its targeting scope to include various european countries. The threat actors often use spear-phishing emails, in conjunction with Google drive links inside, to lure their victims. The group was found attacking government agencies, media, educational and research institutes. The Polaris group uses malware includes Poison Ivy, 9002 RAT, PlugX, CobaltStrike, TOnePipeShell, TVLoad, PlugDisk and ShadowPAD in their attacks. The Polaris group seems to have a strong logistics that is capable of quickly developing and modifying their digital weaspon in different campaigns.",
      "last_updated_at": 1695916800
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Adversaries'

export const APIDocumentVersionTwoMalwareAdversaries = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_MALWARE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_MALWARE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Lists all attack groups that may use the malware.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/malwares/:name/adversaries" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>Malware name</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            adversaries
            <UnorderedList>
              <ListItem>
                Array of related adversaries, each object contains following
                keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>aliases: array of strings</ListItem>
                  <ListItem>origin_countries: array of strings</ListItem>
                  <ListItem>target_countries: array of strings</ListItem>
                  <ListItem>target_industries : array of strings</ListItem>
                  <ListItem>overview: text</ListItem>
                  <ListItem>last_updated_at: unix timestamp</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
