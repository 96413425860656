import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/2ee8743f11ae9bd234c08728c015423cadbed2f8bfb132b8ec18f902968786d5/malwares" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"2ee8743f11ae9bd234c08728c015423cadbed2f8bfb132b8ec18f902968786d5",
  "malwares":[
      {
        "name":"RKORAT",
        "aliases":[],
        "type":"RAT",
        "attribution":"DirtyFuxi",
        "overview":"RKORAT is a cross-platform backdoor written in Golang. We dubbed this malware “RKORAT” because it used “RK_” as the prefix of the environment variable as well as the threat actor named it “ORAT” in the module path. RKORAT supports backdoor functions including upload/download file, screenshot, zip/unzip, kill self, port scan, proxy connection, etc. It also supports three communication protocols, namely, STCP, SUDP, and TCP.",
        "last_updated_at": 1695916800
      }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Malware'

export const APIDocumentVersionTwoSampleMalware = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>Lists all associated malware families.</Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/:sha256/malwares" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The sha256 for sample</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            malwares
            <UnorderedList>
              <ListItem>
                Array of related malwares, each object contains following keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>aliases: array of strings</ListItem>
                  <ListItem>type: string</ListItem>
                  <ListItem>attribution: strings</ListItem>
                  <ListItem>overview: text</ListItem>
                  <ListItem>last_updated_at: unix timestamp</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
