import {
  Box,
  Divider,
  FormControlLabel,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as FilterIcon } from 'assets/basicIcons/filter.svg'
import { Button, LinkButton } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { NoDataWithContactCta } from 'components/NoData/NoDataWithContactCta'
import { PageSection } from 'components/PageSection/PageSection'
import { ThreatLandscapeReport } from 'components/Report/ThreatLandscapeReport'
import { REPORTS_ROUTE } from 'constants/routeParams'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useSearchReportTypeOptions } from 'hooks/useSearchReportTypeOptions'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  fetchLatestReports,
  fetchLatestReportsCancelled,
  selectLatestReports,
  selectLatestReportsLoading,
} from 'store/slices/landing'
import { TSearchReportType } from 'store/types/entityTypes/report'

import { LatestReportsLoading } from './LatestReportsLoading'

export const LATEST_REPORT_CONTAINER_TEST_ID = 'latest-report-container'

const allReportTypes: TSearchReportType[] = [
  'flashReport',
  'monthlyReport',
  'CTRReport',
  'cyberAffairs',
  'VIRReport',
  'customReport',
  'ddwTailoredReport',
]

export const LatestReports = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const searchReportTypeOptions = useSearchReportTypeOptions()

  const latestReportsLoading = useAppSelector(selectLatestReportsLoading)
  const latestReports = useAppSelector(selectLatestReports)
  const { t } = useTranslation(['component', 'landingPage', 'search'])

  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [reportTypes, setReportTypes] =
    useState<TSearchReportType[]>(allReportTypes)

  const isReportTypesIndeterminate =
    reportTypes.length > 0 && reportTypes.length < allReportTypes.length

  const toggleReportFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleReportTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    reportType: TSearchReportType
  ) => {
    let newReportTypes = reportTypes
    if (event.target.checked) {
      newReportTypes = [...newReportTypes, reportType]
    } else {
      newReportTypes = reportTypes.filter((type) => type !== reportType)
    }

    setReportTypes(newReportTypes)
  }

  const handleSelectAllReportTypeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setReportTypes(allReportTypes)
    } else {
      setReportTypes([])
    }
  }

  useEffect(() => {
    dispatch(fetchLatestReports(reportTypes))

    return () => {
      dispatch(fetchLatestReportsCancelled())
    }
  }, [reportTypes])

  return (
    <PageSection
      title={t('latestReports.title', { ns: 'landingPage' })}
      titleAction={
        <Button
          onClick={toggleReportFilter}
          variant="text"
          endIcon={
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={FilterIcon}
              inheritViewBox
            />
          }
        >
          Filter
        </Button>
      }
    >
      <Stack
        sx={{ height: '100%', overflow: 'auto' }}
        data-testid={LATEST_REPORT_CONTAINER_TEST_ID}
      >
        {showFilter && (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              alignItems: 'flex-start',
              flexShrink: 0,
              px: 4,
              py: 1,
              gap: 2,
              backgroundColor: theme.colors.BLACK_90,
              borderBottom: `1px solid ${theme.colors.BLACK_20}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                pt: 0.5,
              }}
            >
              <Typography
                variant="textSmallImportant"
                color={theme.colors.WHITE}
              >
                {t('reportTypeFilter.label', { ns: 'search' })}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            >
              <FormControlLabel
                sx={{
                  flexShrink: 0,
                  mr: 2,
                  '& .MuiFormControlLabel-label': {
                    color: theme.colors.WHITE,
                    ...theme.typography.body,
                  },
                }}
                control={
                  <Checkbox
                    checked={reportTypes.length === allReportTypes.length}
                    indeterminate={isReportTypesIndeterminate}
                    onChange={handleSelectAllReportTypeChange}
                  />
                }
                label={t('reportTypeFilter.all', { ns: 'search' })}
              />
              {searchReportTypeOptions.map((option) => (
                <FormControlLabel
                  key={option.key}
                  sx={{
                    flexShrink: 0,
                    mr: 2,
                    '& .MuiFormControlLabel-label': {
                      color: theme.colors.WHITE,
                      ...theme.typography.body,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={reportTypes.some(
                        (selectedReportType: TSearchReportType) =>
                          selectedReportType === option.key
                      )}
                      onChange={(event) =>
                        handleReportTypeChange(event, option.key)
                      }
                    />
                  }
                  label={option.displayName}
                />
              ))}
            </Box>
          </Box>
        )}
        {latestReportsLoading ? (
          <LatestReportsLoading />
        ) : (
          <Box sx={{ p: 2, bgcolor: theme.colors.BLACK_90, flexGrow: 1 }}>
            {latestReports.length > 0 ? (
              reportTypes.length > 0 ? (
                <Box>
                  <Box>
                    {latestReports.map((report, index) => (
                      <Box key={report.id}>
                        {index > 0 && (
                          <Divider
                            sx={{
                              borderColor: theme.colors.WHITE_20,
                              mb: 1,
                            }}
                          />
                        )}
                        <ThreatLandscapeReport report={report} />
                      </Box>
                    ))}
                  </Box>
                  <Divider sx={{ borderColor: theme.colors.WHITE_20, mb: 1 }} />
                  <LinkButton
                    to={`/${REPORTS_ROUTE}`}
                    size="large"
                    sx={{ my: 5, width: '100%' }}
                    endIcon={
                      <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                    }
                  >
                    {t('latestReports.moreCta', { ns: 'landingPage' })}
                  </LinkButton>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', px: 4 }}>
                  <Typography
                    variant="textSmallImportant"
                    color={theme.colors.WHITE_60}
                  >
                    {t('emptyState.noMatchFilter', { ns: 'component' })}
                  </Typography>
                </Box>
              )
            ) : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NoDataWithContactCta />
              </Box>
            )}
          </Box>
        )}
      </Stack>
    </PageSection>
  )
}
